/* IntroSection.css */

.intro-section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 60px 20px;
    /* background-color: rgba(30, 30, 30, 0.4); */
    color: #FAFAFA; /* Light text color */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 40px 0;
  }
  
  .intro-content {
    flex: 1;
    padding-right: 20px;
  }
  
  .intro-heading {
    font-size: 48px;
    color: #FFCA28; /* Bright Yellow for the heading */
    line-height: 72px;
    margin-bottom: 20px;
  }
  
  .intro-subheading {
    font-size: 24px;
    color: #FF7043; /* Spicy Orange for the subheading */
    margin-bottom: 20px;
  }
  
  .intro-description {
    font-size: 18px;
    color: #E0E0E0; /* Light gray for description */
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .intro-buttons {
    display: flex;
    gap: 20px;
  }

  .talk-to-us-button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #FF7043;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.explore-menu-button {
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #333;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

.talk-to-us-button:hover,
.explore-menu-button:hover {
  background-color: #f0b300;
}

.talk-to-us-button .fa-whatsapp {
  margin-right: 10px;
}

.modal {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: rgba(34, 34, 34, 0.75); /* Dark color with 85% opacity */
    padding: 20px;
    border-radius: 10px;
    width: 60%;
    text-align: center;
    color: #FFCA28; /* Bright Yellow for the heading */

  }
  
  .modal-content p {
    font-size: 1.4rem;
    margin-bottom: 20px;
  }
  
  .modal-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .options-container {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .options-container label {
    display: block;
    margin-bottom: 10px;
    color: #FAFAFA; /* Light text color for contrast */
  }
  
  .options-container input[type="radio"] {
    margin-right: 10px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 10px; /* Space between the buttons */
    margin-top: 20px;
  }
  
  .send-message-button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close-modal-button {
    padding: 10px 20px;
    font-size: 14px;
    margin-top: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
  }
  
  .send-message-button {
    background-color: #25d366;
    color: #fff;
    margin-right: 10px;
  }
  
  .close-modal-button {
    background-color: #f44336;
    color: #fff;
  }
  
  .send-message-button:hover {
    background-color: #1ebe5e;
  }
  
  .close-modal-button:hover {
    background-color: #e31e13;
  }
  
  .btn {
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .btn-primary {
    background-color: #FF7043; /* Spicy Orange */
    color: #FAFAFA;
  }
  
  .btn-primary:hover {
    background-color: #FFCA28; /* Bright Yellow on hover */
    color: #333;
  }
  
  .btn-secondary {
    background-color: #333; /* Dark background for secondary button */
    color: #FAFAFA;
  }
  
  .btn-secondary:hover {
    background-color: #FFCA28; /* Bright Yellow on hover */
    color: #333;
  }
  
  /* .intro-image {
    flex: 1;
    text-align: center;
  }
  
  .intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  } */

  .intro-image {
    flex: 1;
    position: relative;
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
  }
  
  .intro-image img {
    max-width: 100%;
    height: auto;
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 1); /* Soft shadow for blending */
    mask-image: radial-gradient( rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 0) 100%);
    -webkit-mask-image: radial-gradient( rgba(0, 0, 0, 0.8) 70%, rgba(0, 0, 0, 0) 100%);
    transition: transform 0.3s ease;
  }
  
  .intro-image img:hover {
    transform: scale(1.05); /* Slight zoom on hover for effect */
  }
  
  @media (max-width: 1024px) {
    .intro-section {
      flex-direction: column;
      text-align: center;
    }
  
    .intro-content {
      padding-right: 0;
      margin-bottom: 30px;
    }
  
    .intro-buttons {
      justify-content: center;
    }
  
    .intro-image {
      margin-top: 20px;
    }
  }
  