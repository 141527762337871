/* StorySection.css */

.story-section {
    background-color: rgba(30, 30, 30, 0.4); /* Dark gray background consistent with theme */
    color: #FAFAFA; /* Light text color for readability */
    padding: 60px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 40px 0;
    text-align: center;
  }
  
  .story-heading {
    font-size: 36px;
    color: #FFCA28; /* Bright yellow for heading to stand out */
    margin-bottom: 20px;
  }
  
  .story-description {
    font-size: 18px;
    color: #E0E0E0; /* Light gray for description text */
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .story-content {
    max-width: 800px;
    margin: 0 auto;
  }
  