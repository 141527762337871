/* AboutPage.css */

.about-page {
    /* background-color: rgba(30, 30, 30, 1); Dark background consistent with theme */
    color: #FAFAFA; /* Light text color */
    padding: 60px 20px;
    border-radius: 10px;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .about-heading {
    font-size: 48px;
    color: #FFCA28; /* Bright yellow for the main heading */
    text-align: center;
    margin-bottom: 40px;
  }
  
  .about-subheading {
    font-size: 36px;
    color: #FF7043; /* Spicy orange for subheadings */
    margin-top: 40px;
    margin-bottom: 20px;
  }
  
  .about-description {
    font-size: 18px;
    color: #E0E0E0; /* Light gray for description text */
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .about-call-to-action {
    margin-top: 60px;
    text-align: center;
  }
  
  .about-call-to-action p {
    font-size: 20px;
    color: #FAFAFA;
  }
  
  .about-page p:last-child {
    margin-bottom: 0;
  }

  .journey-section {
    align-items: center;
    text-align: center;
    margin-top: 20px;
  }
  
  .timeline {
    position: relative;
    max-width: 80%;
    /* max-width: 1200px; */
    margin: 0 auto;
  }
  
  .timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #FFCA28; /* Highlight color */
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
  }
  
  .timeline-item {
    padding: 20px 30px;
    position: relative;
    width: 50%;
    background-color: inherit;
    color: inherit;
  }
  
  .timeline-item::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: #FFCA28; /* Highlight color */
    border: 4px solid #FFCA28;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .timeline-item .timeline-date {
    position: absolute;
    top: 15px;
    width: 100px;
    text-align: center;
    font-size: 18px;
    color: #FFCA28; /* Highlight color */
  }
  
  .timeline-item:nth-child(odd) {
    left: 0;
  }
  
  .timeline-item:nth-child(even) {
    left: 50%;
  }
  
  .timeline-item:nth-child(even) .timeline-date {
    left: -135px;
  }
  
  .timeline-item:nth-child(odd) .timeline-date {
    left: -100px;
  }
  
  .timeline-item .timeline-content {
    padding: 20px 30px;
    background-color: rgba(44, 44, 44, 0.6); /* Slightly lighter background */
    position: relative;
    border-radius: 6px;
  }
  
  .timeline-item .timeline-content h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #FFCA28; /* Highlight color */
  }
  
  .timeline-item .timeline-content p {
    margin: 0;
    font-size: 16px;
  }

  .timeline-item1 {
    padding: 20px 30px;
    position: relative;
    width: 50%;
    background-color: inherit;
    color: inherit;
  }
  
  .timeline-item1::before {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    left: -12px;
    background-color: #FFCA28; /* Highlight color */
    border: 4px solid #FFCA28;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
  }
  
  .timeline-item1 .timeline-date1 {
    position: absolute;
    top: 15px;
    width: 100px;
    text-align: center;
    font-size: 18px;
    color: #FFCA28; /* Highlight color */
  }
  
  .timeline-item1:nth-child(odd) {
    left: 0;
  }
  
  .timeline-item1:nth-child(even) {
    left: 50%;
  }
  
  .timeline-item1:nth-child(even) .timeline-date1 {
    right: -80px;
  }
  
  .timeline-item1:nth-child(odd) .timeline-date1 {
    right: -100px;
  }
  
  .timeline-item1 .timeline-content {
    padding: 20px 30px;
    background-color: rgba(44, 44, 44, 0.6); /* Slightly lighter background */
    position: relative;
    border-radius: 6px;
  }
  
  .timeline-item1 .timeline-content h3 {
    font-size: 22px;
    margin-bottom: 10px;
    color: #FFCA28; /* Highlight color */
  }
  
  .timeline-item1 .timeline-content p {
    margin: 0;
    font-size: 16px;
  }