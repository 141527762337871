.footer {
    background-color: #1E1E1E;
    color: #fff;
    padding: 20px;
    text-align: center;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .footer-logo h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    padding: 0;
  }
  
  .footer-logo p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .footer-social-icons {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  
  .footer-social-icons a {
    color: #fff;
    font-size: 24px;
    transition: color 0.3s ease;
  }
  
  .footer-social-icons a:hover {
    color: #FFCA28; /* Yellow color on hover */
  }
  
  .footer-bottom {
    border-top: 1px solid #555;
    padding-top: 10px;
    font-size: 14px;
  }
  
  @media (min-width: 768px) {
    .footer-content {
      flex-direction: row;
    }
  
    .footer-logo {
      text-align: left;
    }
  
    .footer-social-icons {
      justify-content: flex-end;
    }
  }
  