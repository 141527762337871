/* HomeDelivery.css */

.home-delivery {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 20px;
    background-color: rgba(0, 0, 0, 0.7); /* Dark background with slight transparency */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    margin: 40px 0;
    background: url('../assets/home_delivery_bg.png');
    background-position: center;
    background-size: auto;
    background-attachment: fixed;
  }

    .home-delivery {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 20px;
    background: none;
    border-radius: 10px;
    overflow: hidden; /* Ensure the blur doesn't extend outside the container */
    margin: 40px 0;
    color: #FAFAFA; /* Light font color for readability */
  }

  
  .home-delivery::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../assets/home_delivery_bg.png');
    background-position: center;
    background-size: auto;
    background-attachment: fixed;
    filter: blur(2px); /* Adjust the blur radius as needed */
    z-index: 0;
    opacity: 0.7; /* Optional: Lower the opacity for a softer blur */
  }

  .home-delivery__content,
  .home-delivery__form {
    position: relative;
    z-index: 1; /* Ensure content is above the blurred background */
  }
  
  .home-delivery__content {
    padding-right: 20px;
  }
  
  .home-delivery__heading {
    font-size: 36px;
    color: #FFCA28; /* Bright Yellow for the heading */
    margin-bottom: 20px;
    padding: 0;
  }

  .home-delivery__desc_container {
    background-color: rgba(0,0,0,0.3);
    padding: 10px;
    border-radius: 10px;
  }
  
  .home-delivery__description {
    font-size: 18px;
    color: #fff; /* Light Gray for description */
    line-height: 1.6;
    margin-bottom: 20px;
  }
  

  
  .home-delivery__image-container {
    flex: 1;
    text-align: center;
  }
  
  .home-delivery__image {
    max-width: 480px;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
  }
  
  @media (max-width: 768px) {
    .home-delivery {
      flex-direction: column;
      text-align: center;
    }
  
    .home-delivery__content {
      padding-right: 0;
      margin-bottom: 20px;
    }
  
    .home-delivery__image {
      width: 80%;
      margin: 0 auto;
    }
  }
  