.home-page {
    padding: 20px;
}

.intro-parent-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}

.intro-section {
    flex: 1;
    /* max-width: 50%; */
    text-align: left;
    margin-bottom: 40px;
    line-height: 28px;
    color: var(--color-golden);
}

.intro-section h2 {
    font-size: 2.5em;
    color: var(--color-golden);
    text-align: center;
}

.intro-section ul {
    font-size: 1em;
    color: #fff;
    /* max-width: 800px; */
    margin: 20px;
    line-height: 1 em;
}

.home-delivery, #party-orders, #contact {
    /* background: #fff; */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}
/* 
#party-orders h2, #contact h2 {
    color: var(--color-golden);
} */

 #party-orders p, #contact p {
    font-size: 1.1em;
    color: #666;
}

.home-delivery-section {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin-top: 0px;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
}


form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

form label {
    margin-top: 10px;
    font-weight: bold;
    color: #333;
}

form input, form textarea {
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
}


.map-container {
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
    .intro-section h2 {
        font-size: 2em;
    }
    .intro-section p {
        font-size: 1em;
        padding: 0 10px;
    }
    .home-delivery, #party-orders, #contact {
        padding: 15px;
    }
    .home-delivery p, #party-orders p, #contact p {
        font-size: 1em;
    }
    .home-delivery-section {
        flex-direction: column;
        text-align: center;
    }

    .home-delivery-content {
        margin-right: 0;
        margin-bottom: 20px;
    }

    .home-delivery-image {
        max-width: 100%;
    }
}
