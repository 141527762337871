h2 {
    font-size: 2em;
    color: #FFCA28;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    /* border-bottom: 3px solid var(--color-golden); */
    /* text-transform: uppercase; */
}

/* Divider between the two divs */
.divider {
    width: 2px;
    background-color: #FAFAFA; /* Color of the divider line */
    height: auto; /* Full height of the divs */
    margin: 0 20px; /* Adjust spacing around the divider */
    opacity: 0.3; /* Make it a bit transparent */
  }

.menu-flex-box {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.dine-in-menu-block {
    width: 65%;
    padding: 20px
}

.menu-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2em;
    color: #FFCA28;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    /* border-bottom: 3px solid var(--color-golden); */
    /* text-transform: uppercase; */
}

.menu-title1 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.4em;
    color: #FFCA28;
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    /* border-bottom: 3px solid var(--color-golden); */
    /* text-transform: uppercase; */
}

.menu-content {
    color: azure;
}

.menu-content1 {
    color: azure;
    margin-bottom: 40px;
}

.menu-category {
    margin: 40px 0;
    padding: 0 20px;
    text-align: center;
}

.menu-image-container {
    width: 95%;
    align-self: center;
    margin: 30px 5% 30px 5%;
}

.menu-image-container img {
    border-radius: 10px; /* Optional: Rounded corners */
    box-shadow: 0px 0px 30px 15px rgba(0, 0, 0, 1); /* Soft shadow for blending */
    mask-image: radial-gradient( rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 1) 100%);
    -webkit-mask-image: radial-gradient( rgba(0, 0, 0, 1) 70%, rgba(0, 0, 0, 1) 100%);
    transition: transform 0.3s ease;
  }

  .menu-image {
    width: 100%;
    margin: 0 auto;
    display: block;
    position: relative;
    filter: brightness(0.9); /* Adjust brightness to blend with background */
    transition: filter 0.3s ease;
  }
  
  .menu-image-container img:hover {
    transform: scale(1.05); /* Slight zoom on hover for effect */
    filter: brightness(1);
  }

  .image-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    pointer-events: none; /* Allow clicks to pass through the overlay */
  }

  .platform-links-container {
    width: 35%;
    position: relative;
    margin: 0 auto;
    padding: 20px;
  }

 /* Lottie Animation */

.lottie-animation-container {
    width: 100%;
    position: relative; /* Stay within document flow */
}

.lottie-animation {

    width: 20%;
    height: 200px; /* Height of the Lottie animation */
    z-index: 1;
    overflow: hidden;
    display: flex;
    align-items: center; /* Center the animation vertically */
    background-color: transparent; /* Ensure background is transparent */
    animation: moveAnimation 10s linear infinite;
  }
  
  @keyframes moveAnimation {
    0% {
      transform: translateX(0%);
    }
    50% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(400%);
    }
  }

.platform-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    margin-bottom: 40px;
    align-items: center
    /* z-index: 2; */
  }
  
  .platform-links a {
    text-decoration: none;
  }
  
  .platform-links a:hover {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }

  .Swiggy_Zomato_Icon {
    width: 120px;
  }

  .magicpin-logo {
    width: 120px;
  }


  .poll {
    margin-top: 40px;
    text-align: center;
  }
  
  .poll h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #2C2C2C; Background color matching the page */
    padding: 20px;
    border-radius: 8px;
  }
  
  .iframe-container iframe {
    width: 100%;
    max-width: 800px;
    height: 800px;
    border: none;
    /* background-color: #2C2C2C; Matching iframe background with page */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
  }

  /* Responsive Adjustments */
@media (max-width: 1000px) {
    .menu-flex-box {
      flex-direction: column;
    }
  
    .dine-in-menu-block,
    .platform-links-container {
      width: 100%;
    }

    .platform-links {
        flex-direction: row;
    }
  
    .lottie-animation-container {
      width: 100%;
      height: 150px;
    }

    .divider {
        display: none; /* Hide the divider on small screens */
      }
  }