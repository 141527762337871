.testimonial-section {
    align-items: center;
}

.testimonial-section h2 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
}

.testimonial-parent {
    padding: 20px;
    text-align: center;
}

.testimonials-slider {
    /* background: #fff; */
    background-color: rgba(44, 44, 44, 0.4);
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.testimonial-section {
    color: #FFCA28
}

.testimonial {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.testimonial-header {
    display: flex;
    /* align-items: center; */
    margin-bottom: 20px;
}

.profile-pic,
.testimonial__initials {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  color: #FAFAFA; /* Light text color */
  background-color: var(--color-golden);
}

.testimonial-info {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial-info h3 {
    margin: 0;
    color: #FFCA28;
}

.rating {
    margin-top: 5px;
}

.star {
    color: #ddd;
    font-size: 20px;
}

.star.filled {
    color: #ffcc00;
}

.testimonial p {
    font-size: 1.2em;
    color: var(--color-crimson);
    margin: 0;
}
