.menu-section {
    padding: 30px 20px;
    text-align: center;
    /* background-color: #121212; Dark background for the section */
  }
  
  .section-title {
    font-size: 36px;
    color: #FFCA28; /* Deep Yellow for the title */
    margin-bottom: 20px;
    align-items: center;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 0px;
  }
  
  .card {
    perspective: 1000px;
  }
  
  .card-inner {
    position: relative;
    width: 100%;
    height: 250px;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  .card:hover .card-inner {
    transform: rotateY(180deg);
  }
  
  .card-front, .card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border: 1px solid #333; /* Dark border to match the theme */
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7); /* Adjusted shadow for dark theme */
  }
  
  .card-front {
    background-color: rgba(30, 30, 30, 0.6); /* Dark gray with transparency */
    
    color: #FAFAFA; /* Light text color for readability */
  }

  .span-front {
    background-color: rgba(30, 30, 30, 0.6); /* Dark gray with transparency */
    text-align: center;
    justify-content: center;
    color: #FAFAFA; /* Light text color for readability */
  }
  
  .card-back {
    background-color: #FFCA28; /* Spicy Orange for the back */
    color: #000; /* Light text color */
    transform: rotateY(180deg);
    padding: 10px;
    border-radius: 10px;
    text-align: left;
  }

  .card-back h3 {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
  }

  .description {
    font-size: 13px;
  }

  .cookingTime {
    font-size: 14px;
  }
  
  .card-back ul {
    list-style-type: none;
    padding-left: 0;
  }

  .card-back ul {
    list-style-type: disc;
    margin-left: 20px; /* Indent for bullet points */
    padding-left: 0;
  }
  
  .card-back ul li {
    font-size: 12px;
    margin-bottom: 5px;
  }
  
  .item-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .item-name {
    font-size: 20px;
    color: #FFCA28; /* Deep Yellow for item names */
    margin: 10px 0;
  }
  
  .description {
    margin: 10px 0;
    color: #000; /* Light text color for descriptions */
  }
  