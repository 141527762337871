@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* background: linear-gradient(135deg, #f7c6c7, #fef9c3, #a2c2e3, #a3d9f0); */
  background-size: 400% 400%;
  /* animation: gradientAnimation 15s ease infinite; */
  min-height: 100vh;
  overflow-x: hidden;
}

.app__bg {
  background: url('./assets/bg.png');
  background-position: center;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: fixed;
}

@keyframes gradientAnimation {
  0% { background-position: 0% 0%; }
  50% { background-position: 100% 100%; }
  100% { background-position: 0% 0%; }
}

.App {
  /* max-width: 1200px; */
  /* margin: auto; */
  /* padding: 20px; */
}

h2 {
  font-family: 'Roboto';
}

p {
  line-height: 1.6;
}
