/* ContactUs.css */

.contact-us {
    background-color: rgba(30, 30, 30, 1); /* Dark gray background */
    color: #FAFAFA; /* Light text color */
    padding: 60px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    margin: 40px 0;
    text-align: center;
  }
  
  .contact-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .contact-heading {
    font-size: 36px;
    color: #FFCA28; /* Bright yellow for heading */
    margin-bottom: 20px;
  }
  
  .contact-description {
    font-size: 18px;
    color: #E0E0E0; /* Light gray for description text */
    line-height: 1.6;
    margin-bottom: 40px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-group {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  
  .form-group label {
    margin-bottom: 8px;
    color: #FFCA28; /* Bright yellow for label */
  }
  
  .form-group input,
  .form-group textarea {
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #666; /* Soft border color */
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent input background */
    color: #FAFAFA; /* Light text color */
  }
  
  .form-group input:focus,
  .form-group textarea:focus {
    border-color: #FFCA28; /* Highlight border on focus */
    outline: none;
  }
  
  .btn-submit {
    padding: 12px 24px;
    border-radius: 5px;
    background-color: #FF7043; /* Spicy Orange */
    color: #FAFAFA;
    font-size: 16px;
    font-weight: bold;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .btn-submit:hover {
    background-color: #FFCA28; /* Bright yellow on hover */
    color: #333;
  }
  