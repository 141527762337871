/* General Header Styles */
.header {
    background: var(--color-black);
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 20px 20px;
    color: #FAFAFA; /* Light text color */
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: transparent;
    backdrop-filter: blur(2xl); /* Applies the blur effect */
  }
  
  .navbar {
    z-index: 10;
    position: sticky;
    top: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.8); /* Initial transparent background */
    backdrop-filter: blur(0px); /* No blur initially */
    padding: 1rem;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
  }
  
  .navbar.scrolled {
    background-color: rgba(255, 255, 255, 0.1); /* More opaque background on scroll */
    backdrop-filter: blur(10px); /* Apply blur on scroll */
  }
  
  .logo-container {
    display: flex;
    align-items: center;
    flex: 1;
  }
  
  .navbar__logo {
    display: flex;
    align-items: center;
  }

  .navbar__logo-link {
    display: flex;
    align-items: center;
  }
  
  .navbar__image {
    width: 100px;
    margin-right: 20px;
  }
  
  .navbar__header {
    font-size: 32px;
    font-weight: bold;
    color: #FFCA28; /* Bright yellow for the restaurant name */
  }
  
  .header h1 {
    margin: 0;
    font-size: 1.5em;
  }
  
  .header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
  .header nav ul li {
    margin: 0;
  }
  
  .header nav ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
  }
  
  .header nav ul li a:hover {
    text-decoration: underline;
  }
  
  /* Container for menu items */
  .navbar__menu {
    display: flex;
    margin-right: 10%;
    gap: 20px;
    justify-content: center;
    align-items: left;
    transition: all 0.3s ease;
    gap: 0.5rem; /* Equivalent to gap-2 in Tailwind */
  }
  
  /* Individual menu items */
  .navbar-item {
    text-decoration: none;
    font-size: 20px;
    transition: color 0.3s;
    color: var(--primary-white); /* Assuming --primary-white is defined in your CSS variables */
    margin: 0.25em; /* Equivalent to my-0 mx-1 */
    cursor: pointer;
  }
  
  /* Hover effect for menu items */
  .navbar__item:hover {
    color: #FFCA28; /* Deep Yellow on hover */
  }
  
  /* Mobile-specific styles */
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
    gap: 4px;
  }
  
  .hamburger-bar {
    width: 25px;
    height: 3px;
    background-color: #fff;
    transition: all 0.3s ease;
  }
  
  /* Open state for mobile menu */
  .navbar__menu.open {
    display: flex;
  }

  .social-icons {
    /* color: #fff; */
    margin-right: 40px;
    font-size: 24px;
    transition: color 0.3s;
    display: flex;
    flex-direction: row;
  }

  .social-icons a {
    margin: 10px 10px;
  }
  
  .social-icons a:hover {
    color: #FFCA28; /* Yellow color on hover */
  }
  
  
  @media (max-width: 1000px) {
    .hamburger {
      display: flex;
      margin-right: 10%;
    }
  
    .navbar__menu {
      display: none;
      position: absolute;
      top: 60px;
      right: 0;
      background-color: rgba(0, 0, 0, 0.5);
      flex-direction: column;
      width: 100%;
      align-items: right;
      padding: 20px 0;
      text-align: right;
    }
  
    .navbar__menu.open {
      display: flex;
    }
  
    .navbar__logo {
      flex: 1;
      justify-content: space-between;
    }
  }
  